import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Controller } from "react-hook-form";
import { FieldValues, UseFormRegister, Control, Path } from "react-hook-form";

type Props<TFieldValues extends FieldValues> = {
  inputRef: Path<TFieldValues>;
  name: string;
  className: string;
  register: UseFormRegister<TFieldValues>;
  values: string;
  label: string;
  control: Control<TFieldValues>;
  astric?: boolean;
  disabled?: boolean;
};

const TextEditor = <TFieldValues extends FieldValues>({
  className,
  inputRef,
  control,
  label,
  name,
  register,
  values,
  astric = true,
  disabled = false,
}: Props<TFieldValues>) => {
  const modules = {
    toolbar: [
      [{ header: ["1", "2", "3", "4", "5", "6"] }],
      ["bold", "italic", "underline"],
      ["link", "image"],
      [{ align: "left" }],
      ["clean"],
      [{ color: [] }, { background: [] }],
      [{ label: "My Custom Label" }], // custom label
    ],
  };

  const formats = [
    "header",
    "font",
    "list",
    "bold",
    "italic",
    "underline",
    "link",
    "image",
    "align",
    "color",
    "background",
  ];

  return (
    <div className={`${className} relative`}>
      <label
        className="px-2 text-xs font-medium absolute left-4 text-[#8C8C8C] bg-[#ffffff] py-2 -top-3"
        htmlFor="quill-editor"
      >
        {label} {astric ? <span className="text-red-600  ">*</span> : <></>}
      </label>
      <Controller
        name={inputRef}
        control={control}
        render={({ field }) => (
          <ReactQuill
            className="right-align-toolbar"
            style={{ background: "#FAFAFA" }}
            formats={formats}
            modules={modules}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </div>
  );
};

export default TextEditor;
