import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "./UserManagementSlice";
import axiosClient from "../../api/api";
import { ApiResponse } from "../../types/apiresponse";

export interface getSCAList {
  id: string;
  name: string;
  headline: string;
  text: string;
  vimeo_link: string;
}

export const getSCAListAPI = createAsyncThunk<ApiResponse<getSCAList[]>>(
  "sca/getSCA",
  async () => {
    const res = await axiosClient.get<ApiResponse<getSCAList[]>>(
      "auth/fundamentals"
    );
    return res.data;
  }
);
export const postSCAListAPI = createAsyncThunk<
  ApiResponse<getSCAList>,
  FormData
>("sca/postSCA", async (data) => {
  const res = await axiosClient.post("auth/fundamental", data);
  return res.data;
});

interface SCASlice {
  getSCAdata: Status;
  postSCAdata: Status;
  SCAdata: getSCAList[];
}

const initialState: SCASlice = {
  getSCAdata: Status.IDLE,
  postSCAdata: Status.IDLE,
  SCAdata: [],
};

const SCASlice = createSlice({
  name: "sca",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSCAListAPI.pending, (state) => {
        state.getSCAdata = Status.LOADING;
      })
      .addCase(getSCAListAPI.fulfilled, (state, data) => {
        state.getSCAdata = Status.SUCCESS;
        state.SCAdata = data.payload.data;
      })
      .addCase(getSCAListAPI.rejected, (state) => {
        state.getSCAdata = Status.ERROR;
      })
      .addCase(postSCAListAPI.pending, (state) => {
        state.postSCAdata = Status.LOADING;
      })
      .addCase(postSCAListAPI.fulfilled, (state, _data) => {
        state.postSCAdata = Status.SUCCESS;
      })
      .addCase(postSCAListAPI.rejected, (state) => {
        state.postSCAdata = Status.ERROR;
      });
  },
});

export const {} = SCASlice.actions;

export default SCASlice.reducer;
