import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import InputComponent from "../../Components/Inputs/InputComponent";
import SelectComponent from "../../Components/Inputs/SelectComponent";
import {
  UserPostAPI,
  UserListAPI,
  AddFreeUserAPI,
  FreeUserListAPI,
} from "../../Redux/features/UserManagementSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import { toast } from "react-toastify";
import { notifyError } from "../../helper";

type Props = {
  setAddFormModal: React.Dispatch<React.SetStateAction<boolean>>;
};

type FormValues = {
  email: string;
  password: string;
  name: string;
  confirmPassword: string;
  type: string;
};

const AddFreeUser = ({ setAddFormModal }: Props) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Invalid email"),
  });

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useAppDispatch();
  const pageNumber = useAppSelector(
    (state) => state.UserManagement.userList.current_page
  );
  const onSubmit = async (data: FormValues) => {
    dispatch(
      AddFreeUserAPI({
        email: data.email,
      })
    ).then((res) => {
      console.log(res);
      setAddFormModal(false);
      dispatch(FreeUserListAPI({ page: pageNumber, userType: "admin" }));
    });
  };

  const formValues = watch();

  const options = [
    {
      value: "admin",
      label: "admin",
    },
    { value: "coach", label: "coach" },
  ];
  const loading = useAppSelector(
    (state) => state.UserManagement.UserPostAPIIdle
  );

  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1).map((error: any) => notifyError(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <div className="p-4 flex flex-col h-full ">
      <h2 className="text-xl font-bold mb-4">Add FreeUser</h2>
      <form onSubmit={handleSubmit(onSubmit)} className=" mb-4">
        <div className="flex flex-col justify-between h-screen">
          <div className="flex flex-col  gap-3 justify-between">
            <InputComponent
              // errorname={errors.email?.message}
              inputRef="email"
              label="Email"
              name="email"
              register={register}
              value={formValues.email}
            />
          </div>

          <div className="flex h-[150px] gap-4">
            <ButtonComponent
              CTA="Save"
              loading={loading}
              buttonType="submit"
              varient="blue"
            />
            <ButtonComponent
              CTA="Cancel"
              onClick={() => setAddFormModal(false)}
              buttonType="button"
              varient="outline"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddFreeUser;
