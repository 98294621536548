import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import UIController from "../features/UIControllerSlice";
import UserManagementSlice from "../features/UserManagementSlice";
import OnboardingQuestionsSlice from "../features/OnboardingQuestionsSlice";
import ObjectSlice from "../features/ObjectSlice";
import SessionSlice from "../features/SessionSlice";
import TagSlice from "../features/TagSlice";
import UnitSlice from "../features/UnitSlice";
import RecallCardsSlice from "../features/RecallCardsSlice";
import TrophiesSlice from "../features/TrophiesSlice";
import GroupSlice from "../features/GroupSlice";
import TrustShotCheckSlice from "../features/TrustShotCheckSlice";
import CoachReviewSlice from "../features/CoachReviewSlice";
import UserAuthSlice from "../features/UserAuthSlice";
import ReportSlice from "../features/ReportSlice";
import UserDashboardSlice from "../features/UserDashboardSlice";
import GuideDrillSlice from "../features/GuideDrillSlice";
import AboutFisioSlice from "../features/AboutFisioSlice";
import AppControlSlice from "../features/AppControlSlice";
import DiscountSlice from "../features/DiscountSlice";
import FaqSlice from "../features/FaqSlice";
import SCASlice from "../features/SCASlice";

export const store = configureStore({
  reducer: {
    UserAuth: UserAuthSlice,
    UserDashboard: UserDashboardSlice,
    UserManagement: UserManagementSlice,
    OnboardingQuestions: OnboardingQuestionsSlice,
    UIController: UIController,
    Object: ObjectSlice,
    Session: SessionSlice,
    Tag: TagSlice,
    Unit: UnitSlice,
    RecallCards: RecallCardsSlice,
    Trophies: TrophiesSlice,
    Group: GroupSlice,
    Coach: CoachReviewSlice,
    TrustShotCheck: TrustShotCheckSlice,
    Report: ReportSlice,
    Guide: GuideDrillSlice,
    AboutFisio: AboutFisioSlice,
    Discount: DiscountSlice,
    AppControl: AppControlSlice,
    faq: FaqSlice,
    sca: SCASlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
