import AdminRoutes from "../auth/AdminRoutes";
import { ReactNode } from "react";
import TrophiesList from "../Pages/Trophies/TrophiesList";
import TrustPage from "../Pages/Trust/TrustPage";
import { objectRoutes } from "../routes/contentrepository/objectRoutes";
import { sessionRoutes } from "../routes/contentrepository/sessionRoutes";
import { unitRoutes } from "../routes/contentrepository/unitRoutes";
import { tagRoutes } from "../routes/contentrepository/tagRoutes";
import { recallCardRoutes } from "../routes/contentrepository/recallCardsRoutes";
import { authRoutes } from "../routes/auth/authRoutes";
import { usermanagementRoutes } from "../routes/usermanagement/usermanagementRoutes";
import { onBoardingRoutes } from "../routes/onBoarding/onBoardingRoutes";
import { coachRoutes } from "../routes/coach/coachRoutes";
import { programRoutes } from "../routes/contentrepository/programRoutes";
import { reportRoutes } from "./report/reportRoutes";
import { userDashboardRoutes } from "./usermanagement/userDashboardRoutes";
import { guideRoutes } from "./contentrepository/guideRoutes";
import { affiliateRoutes } from "./affiliate/affiliateRoutes";
import { appControlRoutes } from "./appcontrol/appControlRoutes";
import { discountCodes } from "./discountcodes/discountCodesRoutes";
import { faqRoutes } from "./Faq/faq";
import { freeUserRoutes } from "./freeUser/freeUser";
import { scaRoutes } from "./sca/sca";

interface AppRoutesInterface {
  id: string;
  name: string;
  path: string;
  module: string;
  component: (props: {}) => JSX.Element;
  auth: (props: { children: ReactNode }) => JSX.Element;
  breadCrumbRoutes?: Array<{
    name: string;
    path: string;
  }>;
}

export const AppRoutes: AppRoutesInterface[] = [
  ...authRoutes,
  ...usermanagementRoutes,
  ...onBoardingRoutes,
  ...objectRoutes,
  ...sessionRoutes,
  ...unitRoutes,
  ...tagRoutes,
  ...recallCardRoutes,
  ...coachRoutes,
  ...programRoutes,
  ...reportRoutes,
  ...userDashboardRoutes,
  ...guideRoutes,
  ...affiliateRoutes,
  ...appControlRoutes,
  ...discountCodes,
  ...faqRoutes,
  ...freeUserRoutes,
  ...scaRoutes,
  {
    id: "26",
    name: "Manage Trophies",
    module: "Locker",
    path: "/contentrepository/trophies",
    component: TrophiesList,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Trophies",
        path: "#",
      },
    ],
  },
  {
    id: "28",
    name: "Trust",
    module: "Locker",
    path: "/trust",
    component: TrustPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Trust",
        path: "#",
      },
    ],
  },
];
