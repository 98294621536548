import AdminRoutes from "../../auth/AdminRoutes";
import SCA from "../../Pages/SCA/SCA";

export const scaRoutes = [
  {
    id: "SCA",
    name: "Self Check Audit",
    module: "SCA",
    path: "/sca",
    component: SCA,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Self Check Audit",
        path: "#",
      },
    ],
  },
];
