import SearchBoxComponent from "../../../../Components/Inputs/SearchBoxComponent";
import ExcelIcon from "../../../../Components/Icons/ExcelIcon";
import TableComponent from "../../../../Components/TableComponent";
import { Status } from "../../../../Redux/features/UserManagementSlice";
import {
  CoachReviewTableHeader,
  ShotCheckReviewTableHeader,
  SwingReviewTableHeader,
} from "../../../../utils/TableColumns";
import PaginationComponent from "../../../../Components/PaginationComponent";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../Redux/app/hooks";
import {
  clearFeedBackData,
  feedbackListHandleSort,
  getFeedback,
} from "../../../../Redux/features/UserDashboardSlice";
import { useParams } from "react-router-dom";
import SelectComponent from "../../../../Components/Inputs/SelectComponent";
import { DropDownStylesV2 } from "../../../../helper";
import { useForm } from "react-hook-form";
import BoxLayout from "../../../../Containers/Layout/BoxLayout";
import TableSkeleton from "../../../../Skeleton/TableSkeleton";
import { useIsMount } from "../../../../CustomHooks/useIsMount";
import CenterPopUpComponent from "../../../../Components/PopUp/CenterPopUpComponent";
import { useOnClickOutside } from "../../../../CustomHooks/useOnClickOutside";
import PopUpTable from "../../../../Components/PopUpTable/PopUpTable";
import ReactPlayer from "react-player";

type Props = {};

const FeedbackPage = (props: Props) => {
  const isMount = useIsMount();

  const { id } = useParams();
  const pageChange = (pageNumber: number) => {
    console.log(pageNumber);
    dispatch(
      getFeedback({
        page: pageNumber,
        type: feedBackType,
        filteredValue: userListValue,
        perPage: 10,
        id: id || "",
      })
    ).then((response) => {});
  };
  const dispatch = useAppDispatch();
  const feedBackAPIStatus = useAppSelector(
    (state) => state.UserDashboard.getFeedbackAPIStatus
  );
  const feedBackList = useAppSelector(
    (state) => state.UserDashboard.userFeedbackData
  );
  const sortType = useAppSelector(
    (state) => state.UserDashboard.feedbackSortType
  );
  const active = useAppSelector(
    (state) => state.UserDashboard.currentFeedbackPage
  );
  const total = useAppSelector((state) => state.UserDashboard.feedBackListPage);
  const sortValue = useAppSelector(
    (state) => state.UserDashboard.feedbackSortValue
  );
  const { control, watch } = useForm();
  const feedBackType = watch("feedBackType");
  const [userListValue, setUserListValue] = useState("");
  const getSearchValue = (d: string) => {
    setUserListValue(d);
    // dispatch(UserListAPI({ page: 1, userType: "admin", searchValue: d }));
  };
  console.log(id, "idd");
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (id && feedBackType) {
        dispatch(
          getFeedback({
            page: 1,
            type: feedBackType,
            filteredValue: userListValue,
            perPage: 10,
            id: id || "",
          })
        ).then((response) => {});
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userListValue, feedBackType]);

  const [showComment, setShowComment] = useState(false);
  const [Comment, setComment] = useState("");

  const [showVideo, setShowVideo] = useState(false);
  const [video, setVideo] = useState("");
  const [videoFaceOn, setVideoFaceOn] = useState("");

  const [showReview, setShowReview] = useState(false);
  const [review, setreview] = useState("");
  const [reviewFaceOn, setreviewFaceOn] = useState("");

  const [showSummary, setShowSummary] = useState("");

  const handleCommentCheck = (comment: string) => {
    if (comment !== "") {
      setShowComment(true);
      setComment(comment);
    }
  };

  console.log(video);
  const handleVideoCheck = (link: string, link2: string) => {
    console.log(link);
    console.log(link);
    if (link !== "" || link2 !== "") {
      setShowVideo(true);
      setVideo(link);
      setVideoFaceOn(link2);
    }
  };
  const handleReviewVideoCheck = (link: string, link2: string) => {
    console.log(link);
    if (link !== "" || link2 !== "") {
      setShowReview(true);
      setreview(link);
      setreviewFaceOn(link2);
    }
  };

  // useEffect(() => {
  //     if (id && feedBackType) {
  //         dispatch(getFeedback({ id: id, type: feedBackType, page: 1 }))
  //     }
  // }, [feedBackType])

  useEffect(() => {
    if (id && feedBackType && !isMount) {
      dispatch(
        getFeedback({
          filteredValue: "",
          page: 1,
          perPage: 10,
          type: feedBackType,
          sortType: sortType,
          sortValue: sortValue,
          id: id,
        })
      );
    }
  }, [sortType, feedBackType]);

  const manageSorting = (type: sortValues, value: string) => {
    switch (type) {
      case "ascending":
        dispatch(
          feedbackListHandleSort({ sortType: "descending", sortValue: value })
        );
        break;
      case "descending":
        dispatch(
          feedbackListHandleSort({ sortType: "ascending", sortValue: value })
        );
        break;
      default:
        dispatch(
          feedbackListHandleSort({ sortType: "ascending", sortValue: value })
        );
        break;
    }
  };

  console.log(showSummary);

  const handleShowSummary = (id: string) => {
    console.log(id);
    if (id !== showSummary) {
      setShowSummary(id);
    } else {
      setShowSummary("");
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearFeedBackData());
    };
  }, []);

  const summaryBox = useRef<HTMLDivElement>(null);

  useOnClickOutside(summaryBox, () => {
    setShowSummary("");
  });

  return (
    <>
      <BoxLayout HeaderName="User Profile" sectionName="">
        <div className="h-[calc(100vh-87.6px-7.5rem)]  rounded-md border m-2">
          <div className="flex gap-4 px-4 py-2 justify-between">
            <div className="font-semibold items-center h-[3.5rem] flex gap-2">
              <p className="w-52">Feedback List</p>
              <div className="w-full">
                <SelectComponent
                  selectStyle={DropDownStylesV2}
                  control={control}
                  placeHolder="Choose Rating"
                  inputRef="feedBackType"
                  options={[
                    {
                      label: "Shot Check",
                      value: "shot_check",
                    },
                    {
                      label: "Swing Videos",
                      value: "swing",
                    },
                    {
                      label: "Coach Reviews",
                      value: "coach_review",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex gap-3 items-center justify-center">
              <SearchBoxComponent getSearchValue={getSearchValue} />{" "}
              <ExcelIcon />
            </div>
          </div>
          {
            feedBackAPIStatus === Status.SUCCESS &&
              feedBackList.length !== 0 && (
                // <div className='flex flex-col justify-between'>
                <>
                  <TableComponent
                    headerPadding="px-2"
                    sortType={sortType}
                    sortValue={sortValue}
                    onClick={manageSorting}
                    handleSortAction={feedbackListHandleSort}
                    heightClass={"h-[calc(100vh-87.6px-15rem)]"}
                    loader={feedBackAPIStatus}
                    TableHeaderData={
                      feedBackType === "coach_review"
                        ? CoachReviewTableHeader
                        : feedBackType === "swing"
                        ? SwingReviewTableHeader
                        : ShotCheckReviewTableHeader
                    }
                    sortIcon
                    staticHeight
                  >
                    <tbody className="">
                      {feedBackList.map((i) => {
                        if (i.type === "Coach Reviews:") {
                          return (
                            <tr className="text-[#767576] text-sm px-2 border-b">
                              <td className="font-semibold px-2 text-[#212121]">
                                {i.type}
                              </td>
                              <td className="font-medium py-4 px-2 text-[#212121]">
                                {i.unit}
                              </td>
                              <td className="font-medium py-4 px-2 text-[#212121]">
                                {i.session}
                              </td>
                              <td
                                className="underline flex px-2 items-center mt-3 gap-2"
                                onClick={() =>
                                  handleReviewVideoCheck(
                                    i.down_the_line,
                                    i.face_on
                                  )
                                }
                              >
                                <button>
                                  <button>
                                    <img
                                      src="/images/cards/linkicon.svg"
                                      alt=""
                                    />
                                  </button>
                                  Coach Review
                                </button>
                              </td>
                              <td className="font-medium py-4 px-2 text-[#212121]">
                                {i.date}
                              </td>
                              <td className="font-medium py-4 px-2 text-[#212121]">
                                {i.status === "Completed" ? (
                                  <span className="bg-[#B9F6CA] rounded-full py-1 px-2 text-[0.75rem] text-[#00C853]">
                                    {i.status}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {i.status === "In Progress" ? (
                                  <span className="bg-[#FFF8E1] rounded-full py-1 px-2 text-[0.75rem] text-[#FFC107]">
                                    {i.status}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {i.status === "Pending" ? (
                                  <span className="bg-[#D7F1F4] rounded-full py-1 px-2 text-[0.75rem] text-[#58BBCF]">
                                    {i.status}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                <button
                                  onClick={() => handleCommentCheck(i.comment)}
                                >
                                  <img src="/images/comment.svg" alt="" />
                                </button>
                              </td>
                            </tr>
                          );
                        } else if (i.type === "Swing Videos:") {
                          return (
                            <tr className="text-[#767576] text-sm px-2 border-b">
                              <td className="font-semibold px-2 text-[#212121]">
                                {i.type}
                              </td>
                              <td className="font-medium py-4 text-[#212121]">
                                {i.date}
                              </td>
                              <td
                                className="underline flex items-center mt-3 gap-2"
                                onClick={() =>
                                  handleVideoCheck(i.down_the_line, i.face_on)
                                }
                              >
                                <button>
                                  <button>
                                    <img
                                      src="/images/cards/linkicon.svg"
                                      alt=""
                                    />
                                  </button>
                                  Swing Video
                                </button>
                              </td>
                            </tr>
                          );
                        } else {
                          return (
                            <tr className="text-[#767576] text-sm px-2 border-b">
                              <td className="font-semibold px-2 text-[#212121]">
                                Shotcheck
                              </td>
                              <td className="font-medium py-4 text-[#212121]">
                                {i.date}
                              </td>

                              <td className="">
                                <button
                                  className="relative w-[15rem]"
                                  onClick={() => handleShowSummary(i.date)}
                                >
                                  <img
                                    src="/images/cards/calender.svg"
                                    alt=""
                                  />
                                  {showSummary === i.date && (
                                    <PopUpTable
                                      check={i}
                                      summaryBox={summaryBox}
                                    />
                                  )}
                                </button>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </TableComponent>
                  <PaginationComponent
                    active={Number(active)}
                    total={total}
                    paginationFunction={pageChange}
                  />
                </>
              )
            // </div>
          }
          {feedBackAPIStatus === Status.LOADING && (
            <>
              <TableComponent
                heightClass={"h-[calc(100vh-87.6px-15rem)]"}
                loader={feedBackAPIStatus}
                TableHeaderData={
                  feedBackType === "coach_review"
                    ? CoachReviewTableHeader
                    : feedBackType === "swing"
                    ? SwingReviewTableHeader
                    : ShotCheckReviewTableHeader
                }
                sortIcon
                staticHeight
              >
                <TableSkeleton
                  tableColumns={
                    feedBackType === "coach_review"
                      ? 7
                      : feedBackType === "swing"
                      ? 3
                      : 4
                  }
                />
              </TableComponent>
              <PaginationComponent active={1} total={1} />
            </>
          )}
          {feedBackType &&
            feedBackAPIStatus === Status.SUCCESS &&
            feedBackList.length === 0 && (
              <>
                <TableComponent
                  heightClass={""}
                  loader={feedBackAPIStatus}
                  TableHeaderData={
                    feedBackType === "coach_review"
                      ? CoachReviewTableHeader
                      : feedBackType === "swing"
                      ? SwingReviewTableHeader
                      : ShotCheckReviewTableHeader
                  }
                  sortIcon
                  staticHeight
                >
                  <></>
                </TableComponent>

                <div className="h-[calc(100vh-87.6px-17rem)] flex flex-col justify-center">
                  <p className=" flex justify-center text-[1.25rem]  font-black text-[#757575]">
                    No Data Available
                  </p>
                  <p className="font-medium flex justify-center text-[0.875rem] text-[#757575]">
                    You can choose Category by clicking the “choose Category “
                    Dropdown at the left side of the screen
                  </p>
                </div>
                <PaginationComponent active={1} total={1} />
              </>
            )}
          {!feedBackType && feedBackList.length === 0 && (
            <>
              <TableComponent
                heightClass={""}
                loader={feedBackAPIStatus}
                TableHeaderData={
                  feedBackType === "coach_review"
                    ? CoachReviewTableHeader
                    : feedBackType === "swing"
                    ? SwingReviewTableHeader
                    : ShotCheckReviewTableHeader
                }
                sortIcon
                staticHeight
              >
                <></>
              </TableComponent>

              <div className=" h-[calc(100vh-87.6px-17rem)] flex flex-col justify-center">
                <p className="  flex justify-center text-[1.25rem]  font-black text-[#757575]">
                  You’ve not Choose Category
                </p>
                <p className="font-medium flex justify-center text-[0.875rem] text-[#757575]">
                  You can choose Category by clicking the “choose Category “
                  Dropdown at the left side of the screen
                </p>
              </div>
              <PaginationComponent active={1} total={1} />
            </>
          )}
        </div>
      </BoxLayout>
      {showComment ? (
        <CenterPopUpComponent
          onClose={() => setShowComment(false)}
          isOpen={showComment}
          message="Coach Reviews"
        >
          <div className="mx-8  pb-3 flex flex-col gap-3">
            <h1 className="text-[#191919] font-medium text-[0.875rem]">
              Coach Comment
            </h1>
            <p className="text-[#9e9e9e] font-medium text-[0.875rem]">
              {Comment}
            </p>
            <p className="flex items-center gap-2">
              <button>
                <img src="/images/cards/linkicon.svg" alt="" />
              </button>
              <span className="text-sm text-[#9e9e9e]">Link name</span>
            </p>
          </div>
        </CenterPopUpComponent>
      ) : (
        <></>
      )}
      {showReview ? (
        <CenterPopUpComponent
          onClose={() => setShowReview(false)}
          isOpen={showReview}
          message="Coach Reviews"
        >
          <>
            <div className="grid grid-cols-2 gap-4  rounded-none ">
              <div className="max-h-[70vh] w-[]">
                <ReactPlayer width="100%" height="100%" url={review} controls />
              </div>
              <div className="max-h-[70vh] w-[]">
                <ReactPlayer
                  width="100%"
                  height="100%"
                  url={reviewFaceOn}
                  controls
                />
              </div>
            </div>
          </>
        </CenterPopUpComponent>
      ) : (
        <></>
      )}
      {showVideo ? (
        <CenterPopUpComponent
          onClose={() => setShowVideo(false)}
          isOpen={showVideo}
          message="Swing Video"
        >
          <div className="grid grid-cols-2 gap-4  rounded-none ">
            <div className="max-h-[70vh] w-[]">
              <ReactPlayer width="100%" height="100%" url={video} controls />
            </div>
            <div className="max-h-[70vh] w-[]">
              <ReactPlayer
                width="100%"
                height="100%"
                url={videoFaceOn}
                controls
              />
            </div>
          </div>
        </CenterPopUpComponent>
      ) : (
        <></>
      )}
    </>
  );
};

export default FeedbackPage;
