import SearchBoxComponent from "../../../../Components/Inputs/SearchBoxComponent";
import ExcelIcon from "../../../../Components/Icons/ExcelIcon";
import TableComponent from "../../../../Components/TableComponent";
import { Status } from "../../../../Redux/features/UserManagementSlice";
import {
  CoachReviewTableHeader,
  notificationTableHeader,
  ShotCheckReviewTableHeader,
  SwingReviewTableHeader,
} from "../../../../utils/TableColumns";
import PaginationComponent from "../../../../Components/PaginationComponent";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../Redux/app/hooks";
import {
  clearFeedBackData,
  notificationListHandleSort,
  getNotificationlist,
} from "../../../../Redux/features/UserDashboardSlice";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import BoxLayout from "../../../../Containers/Layout/BoxLayout";
import TableSkeleton from "../../../../Skeleton/TableSkeleton";
import { useIsMount } from "../../../../CustomHooks/useIsMount";
import { useOnClickOutside } from "../../../../CustomHooks/useOnClickOutside";
import moment from "moment";
import CenterPopUpComponent from "../../../../Components/PopUp/CenterPopUpComponent";

type Props = {};

const NotificationPage = (props: Props) => {
  const isMount = useIsMount();

  const { id } = useParams();
  const pageChange = (pageNumber: number) => {
    console.log(pageNumber);
    dispatch(
      getNotificationlist({
        page: pageNumber,

        filteredValue: userListValue,
        perPage: 10,
        id: id || "",
      })
    ).then((response) => {});
  };
  const dispatch = useAppDispatch();
  const feedBackAPIStatus = useAppSelector(
    (state) => state.UserDashboard.getNotificationlistAPIStatus
  );
  const notificationList = useAppSelector(
    (state) => state.UserDashboard.notifications
  );
  const sortType = useAppSelector(
    (state) => state.UserDashboard.feedbackSortType
  );

  const total = useAppSelector(
    (state) => state.UserDashboard.notificationListPage
  );
  const sortValue = useAppSelector(
    (state) => state.UserDashboard.feedbackSortValue
  );

  const [userListValue, setUserListValue] = useState("");
  const getSearchValue = (d: string) => {
    setUserListValue(d);
  };
  console.log(id, "idd");
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (id) {
        dispatch(
          getNotificationlist({
            page: 1,
            filteredValue: userListValue,
            perPage: 10,
            id: id || "",
          })
        ).then((response) => {});
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userListValue]);

  const [showSummary, setShowSummary] = useState("");

  useEffect(() => {
    if (id && !isMount) {
      dispatch(
        getNotificationlist({
          filteredValue: "",
          page: 1,
          perPage: 10,
          sortType: sortType,
          sortValue: sortValue,
          id: id,
        })
      );
    }
  }, [sortType]);

  const manageSorting = (type: sortValues, value: string) => {
    switch (type) {
      case "ascending":
        dispatch(
          notificationListHandleSort({
            sortType: "descending",
            sortValue: value,
          })
        );
        break;
      case "descending":
        dispatch(
          notificationListHandleSort({
            sortType: "ascending",
            sortValue: value,
          })
        );
        break;
      default:
        dispatch(
          notificationListHandleSort({
            sortType: "ascending",
            sortValue: value,
          })
        );
        break;
    }
  };

  console.log(showSummary);

  useEffect(() => {
    return () => {
      dispatch(clearFeedBackData());
    };
  }, []);

  const summaryBox = useRef<HTMLDivElement>(null);

  useOnClickOutside(summaryBox, () => {
    setShowSummary("");
  });
  const totalPage = useAppSelector(
    (state) => state.UserDashboard.notificationListPage
  );
  const currentPage = useAppSelector(
    (state) => state.UserDashboard.currentNotificationPage
  );

  const [details, setDetails] = useState<{
    created_at: string;
    description: string;
    is_read: true;
    title: string;
    updated_at: string;
    user_id: number;
    _id: string;
  } | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const handleopenDetails = (details: {
    created_at: string;
    description: string;
    is_read: true;
    title: string;
    updated_at: string;
    user_id: number;
    _id: string;
  }) => {
    setDetails(details);
    setModalOpen(true);
  };
  const handlecloseDetails = () => {
    setDetails(null);
    setModalOpen(false);
  };

  return (
    <>
      <BoxLayout HeaderName="User Profile" sectionName="">
        <div className="h-[calc(100vh-87.6px-7.5rem)]  rounded-md border m-2">
          <div className="flex gap-4 px-4 py-2 border-b justify-between">
            <div className="font-semibold items-center  h-[3.5rem] flex gap-2">
              <p className="w-52">Notification List</p>
            </div>
            <div className="flex gap-3 items-center justify-center">
              <SearchBoxComponent getSearchValue={getSearchValue} />{" "}
              <ExcelIcon />
            </div>
          </div>
          {feedBackAPIStatus === Status.SUCCESS &&
            notificationList.length !== 0 && (
              <>
                <TableComponent
                  headerPadding="px-2"
                  sortType={sortType}
                  sortValue={sortValue}
                  onClick={manageSorting}
                  handleSortAction={notificationListHandleSort}
                  heightClass={"h-[calc(100vh-87.6px-15rem)]"}
                  loader={feedBackAPIStatus}
                  TableHeaderData={notificationTableHeader}
                  sortIcon
                  staticHeight
                >
                  <tbody className="">
                    {notificationList.map((i) => {
                      return (
                        <tr className="text-[#767576] text-xs px-2 border-t">
                          <td className=" px-2 text-[#212121]">{i.title}</td>
                          <td className="font-medium py-4 text-[#212121]">
                            {moment(i.created_at).format("DD/MM/yyyy hh:mm a")}
                          </td>
                          <td
                            className="font-medium  cursor-pointer text-[#212121]"
                            onClick={() => handleopenDetails(i)}
                          >
                            <img
                              className="h-6"
                              src={"/images/actionbutton.svg"}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </TableComponent>
                <PaginationComponent
                  active={Number(currentPage)}
                  total={total}
                  paginationFunction={pageChange}
                />
              </>
            )}
          {feedBackAPIStatus === Status.LOADING && (
            <>
              <TableComponent
                heightClass={"h-[calc(100vh-87.6px-15rem)]"}
                loader={feedBackAPIStatus}
                TableHeaderData={notificationTableHeader}
                sortIcon
                staticHeight
                headerPadding="px-2"
              >
                <TableSkeleton tableColumns={3} />
              </TableComponent>
              <PaginationComponent active={1} total={1} />
            </>
          )}
          {feedBackAPIStatus === Status.SUCCESS &&
            notificationList.length === 0 && (
              <>
                <TableComponent
                  heightClass={""}
                  loader={feedBackAPIStatus}
                  TableHeaderData={notificationTableHeader}
                  sortIcon
                  staticHeight
                  headerPadding="px-2"
                >
                  <></>
                </TableComponent>

                <div className="h-[calc(100vh-87.6px-17rem)] flex flex-col justify-center">
                  <p className=" flex justify-center text-[1.25rem]  font-black text-[#757575]">
                    No Data Available
                  </p>
                  <p className="font-medium flex justify-center text-[0.875rem] text-[#757575]"></p>
                </div>
                <PaginationComponent active={1} total={totalPage} />
              </>
            )}
        </div>

        <>
          <CenterPopUpComponent
            isOpen={modalOpen}
            message="Notification Detail"
            onClose={() => handlecloseDetails()}
            confirmAction={() => {}}
          >
            {details && (
              <div className="flex flex-col gap-1 px-6">
                <h1 className="text-xl font-semibold">{details?.title}</h1>
                <h3 className="text-sm italic font-semibold">
                  {moment(details?.created_at).format("DD/MM/yyyy hh:mm a")}
                </h3>
                <p className="text-base"> {details?.description}</p>
              </div>
            )}
          </CenterPopUpComponent>
        </>
      </BoxLayout>
    </>
  );
};

export default NotificationPage;
