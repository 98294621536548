import trust from "../assets/sidebar/icons/trust.svg";
import admin from "../assets/sidebar/icons/admin.svg";
import user from "../assets/sidebar/icons/userdashboard.svg";
import tag from "../assets/sidebar/icons/tag.svg";
import affiliate from "../assets/sidebar/icons/affiliate.svg";
import report from "../assets/sidebar/icons/report.svg";
import contentRepository from "../assets/sidebar/icons/contentrepository.svg";
import FTUE from "../assets/sidebar/icons/FTUE.svg";
import Faq from "../assets/sidebar/icons/Faq.svg";
import sca from "../assets/sidebar/icons/sca.svg";
import CoachReview from "../assets/sidebar/icons/coachreview.svg";
import trustActive from "../assets/sidebar/activeicons/trust.svg";
import adminActive from "../assets/sidebar/activeicons/admin.svg";
import userActive from "../assets/sidebar/activeicons/userdashboard.svg";
import tagActive from "../assets/sidebar/activeicons/tag.svg";
import affiliateActive from "../assets/sidebar/activeicons/affiliate.svg";
import reportActive from "../assets/sidebar/activeicons/report.svg";
import contentRepositoryActive from "../assets/sidebar/activeicons/contentrepository.svg";
import FTUEActive from "../assets/sidebar/activeicons/FTUE.svg";
import CoachReviewActive from "../assets/sidebar/activeicons/coachreview.svg";
import FaqActive from "../assets/sidebar/activeicons/Faq.svg";
import scaActive from "../assets/sidebar/activeicons/sca.svg";

export const sidebarImages = {
  trust,
  trustActive,
  admin,
  adminActive,
  user,
  userActive,
  tag,
  tagActive,
  sca,
  scaActive,
  affiliate,
  affiliateActive,
  report,
  reportActive,
  contentRepository,
  contentRepositoryActive,
  FTUE,
  FTUEActive,
  CoachReview,
  CoachReviewActive,
  Faq,
  FaqActive,
};

export const globalImages = {};

// const images = {
//   ...sidebarImages,
//   ...globalImages,
//   ...navbarImages,
// };
