import AdminRoutes from "../../auth/AdminRoutes";
import FAQ from "../../Pages/Faq/Faq";
import FreeUserList from "../../Pages/freeUser/FreeuserList";

export const freeUserRoutes = [
  {
    id: "freeUser",
    name: "freeUser",
    module: "freeUser",
    path: "/freeUser",
    component: FreeUserList,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Free User",
        path: "#",
      },
    ],
  },
];
