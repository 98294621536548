import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import QuestionsOptions from "../../Components/QuestionsOptions";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { UpdateQuestionAPI } from "../../Redux/features/OnboardingQuestionsSlice";
import { useLocation, useNavigate } from "react-router-dom";
import InputComponent from "../../Components/Inputs/InputComponent";
import SelectComponent from "../../Components/Inputs/SelectComponent";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import classNames from "classnames";
import { notifyError } from "../../helper";

type FormValues = {
  question: string;
  question_type: string;
  can_skip: string;
  subtitle: string;
  text_ip_1: string;
  text_ip_10: string;
};

const EditOnBoardingQuestionPage = () => {
  const location = useLocation();
  const {
    answerList,
    question,
    questionType,
    question_id,
    can_skip,
    subtitle,
    text_ip_1,
    text_ip_10,
  } = location.state;

  const [selectAnswerList, setSelectanswerList] = useState(answerList);
  const options = [
    { label: "Radio", value: "radio" },
    { label: "Multi Choice", value: "multiple_choice" },
    { label: "OpenText Box", value: "opentext_box" },
    { label: "Rating Question", value: "rating" },
  ];

  useEffect(() => {
    document.title = "FISIO | Edit On Boarding Question";
  }, []);
  const validationSchema = Yup.object().shape({
    can_skip: Yup.string().required("A selection is required"),
    question_type: Yup.string(),
    question: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  });
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      question: question,
      question_type: questionType,
      can_skip: can_skip ? "1" : "0",
      subtitle: subtitle,
      text_ip_1: text_ip_1,
      text_ip_10: text_ip_10,
    },
  });

  useEffect(() => {
    setValue("question", question);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);
  const sortType = useAppSelector(
    (state) => state.OnboardingQuestions.sortType
  );
  const formValues = watch();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onSubmit = (data: FormValues) => {
    let hasNoEmptyString = selectAnswerList.every((item: any) => item !== "");
    if (
      formValues.question_type !== "opentext_box" &&
      formValues.question_type !== "rating"
    ) {
      if (hasNoEmptyString && selectAnswerList.length >= 2) {
        dispatch(
          UpdateQuestionAPI({
            text: data.question,
            answers: selectAnswerList,
            question_type: data.question_type,
            question_id: question_id,
            can_skip: data.can_skip !== "0" ? true : false,
          })
        ).then((res) => {
          // closeForm();
          navigate("/onboardingquestionsrepository");
          // dispatch(QuestionListAPI({ sortType }));
        });
      } else {
        if (selectAnswerList.length >= 2) {
          notifyError("Please enter the value in all options.");
        } else {
          notifyError("Please add at least 2 options");
        }
      }
    } else if (formValues.question_type === "rating") {
      dispatch(
        UpdateQuestionAPI({
          question_id: question_id,
          text: data.question,
          answers: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
          question_type: data.question_type,
          subtitle: data.subtitle,
          text_ip_1: data.text_ip_1,
          text_ip_10: data.text_ip_10,
          can_skip: data.can_skip !== "0" ? true : false,
        })
      ).then((res) => navigate("/onboardingquestionsrepository"));
    } else {
      dispatch(
        UpdateQuestionAPI({
          text: data.question,
          answers: ["opentextbox"],
          question_type: data.question_type,
          question_id: question_id,
          can_skip: data.can_skip !== "0" ? true : false,
          subtitle: data.subtitle,
        })
      ).then((res) => navigate("/onboardingquestionsrepository"));
    }
  };
  const loading = useAppSelector(
    (state) => state.OnboardingQuestions.UpdateQuestionAPIIdle
  );
  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1).map((error: any) => notifyError(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
  console.log(watch(), "ss");
  return (
    <BoxLayout
      HeaderName="On Boarding questions"
      sectionName="Edit On Boarding Question"
    >
      <div className="p-4 h-full">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-[90%] justify-between flex-col gap-4"
        >
          <div>
            <div className="grid grid-cols-3 gap-4 items-center">
              <SelectComponent<FormValues>
                control={control}
                inputRef="question_type"
                placeHolder="Select Question Type"
                options={options}
                label="Question Type"
              />

              <InputComponent
                // errorname={errors.question?.message}
                inputRef="question"
                label="Question"
                name="Quiestion"
                register={register}
                value={formValues.question}
              />
              <div className="col-span-1 justify-start flex flex-col gap-2 text-xs ml-4">
                <p className="mt-[-10px] text-[#757575]">Permission</p>
                <div className="flex">
                  <div className="flex flex-wrap gap-5 items-center">
                    <label className="flex gap-1 items-center ">
                      <input type="radio" {...register("can_skip")} value={0} />
                      <span className="custom-radio"></span> {"Required"}
                    </label>

                    <label className="flex gap-1 items-center">
                      <input type="radio" {...register("can_skip")} value={1} />
                      <span className="custom-radio"></span> {"Optional"}
                    </label>
                  </div>
                </div>
              </div>

              <div
                className={classNames("col-span-2", {
                  hidden: formValues.question_type !== "opentext_box",
                })}
              >
                <InputComponent
                  // errorname={errors.question?.message}
                  inputRef="subtitle"
                  label="Subtitle"
                  name="Quiestion"
                  register={register}
                  value={formValues.question}
                />
              </div>
              <div
                className={classNames("col-span-1", {
                  hidden: formValues.question_type !== "rating",
                })}
              >
                <InputComponent
                  // errorname={errors.question?.message}
                  inputRef="text_ip_1"
                  label="Text No. 1"
                  name="Quiestion"
                  register={register}
                  value={formValues.text_ip_1}
                />
              </div>
              <div
                className={classNames("col-span-1", {
                  hidden: formValues.question_type !== "rating",
                })}
              >
                <InputComponent
                  // errorname={errors.question?.message}
                  label="Text No. 10"
                  inputRef="text_ip_10"
                  name="Quiestion"
                  register={register}
                  value={formValues.text_ip_10}
                />
              </div>
            </div>

            <div
              className={classNames("py-3  ", {
                invisible:
                  formValues.question_type === "opentext_box" ||
                  formValues.question_type === "rating",
              })}
            >
              <QuestionsOptions
                answerList={selectAnswerList}
                setAnswerList={setSelectanswerList}
              />
            </div>
          </div>
          <div className="grid grid-cols-10 gap-4">
            <ButtonComponent
              CTA="Back"
              className="col-start-9"
              varient="outline"
              buttonType={"button"}
              onClick={() => navigate("/onboardingquestionsrepository")}
            />
            <ButtonComponent
              loading={loading}
              CTA="Save"
              varient="orange"
              buttonType={"submit"}
            />
          </div>
        </form>
      </div>
    </BoxLayout>
  );
};

export default EditOnBoardingQuestionPage;
