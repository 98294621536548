import React, { useEffect, useState } from "react";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import InputComponent from "../../Components/Inputs/InputComponent";
import TextAreaComponent from "../../Components/Inputs/TextAreaComponent";
import { useForm } from "react-hook-form";
import editicon from "../../assets/edit.png";
import activeediticon from "../../assets/activeedit.png";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import classnames from "classnames";
import {
  getSCAList,
  getSCAListAPI,
  postSCAListAPI,
} from "../../Redux/features/SCASlice";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import UploadFileComponents from "../../Components/Inputs/UploadFileComponents";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import { notifyError, notifySuccess } from "../../helper";
import TextEditor from "../../Components/Inputs/TextEditor";
import ScreenLoader from "../../Components/LoadingContainer/LoadingContainer";
import { Status } from "../../Redux/features/UserManagementSlice";

type Props = {};
const vimeoUrlRegex = /^(https?:\/\/)?(www\.)?(vimeo.com\/)(\d+)(\S+)?$/;

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters long"),
  description: Yup.string()
    .required("Description is required")
    .min(2, "Description must be at least 2 characters long"),
  vimeolink: Yup.string()
    .nullable()
    .test(
      "is-vimeo-link-valid",
      "Enter a valid Vimeo link",
      function (value: any) {
        const { file } = this.parent; // Access sibling field
        if (!value && !file) {
          return this.createError({
            message: "Either a Vimeo link or a Video is required",
            path: "vimeolink",
          });
        }
        if (value && !vimeoUrlRegex.test(value)) {
          return this.createError({
            message: "Enter a valid Vimeo link",
            path: "vimeolink",
          });
        }
        return true;
      }
    ),

  file: Yup.mixed()
    .nullable()
    .test(
      "is-video-file",
      "Only video files are allowed",
      function (value: any) {
        const { vimeolink } = this.parent; // Access sibling field
        if (!value && !vimeolink) {
          return this.createError({
            message: "Either a Vimeo link or a Video is required",
            path: "file",
          });
        }
        if (value && value[0]?.type && !value[0].type.startsWith("video/")) {
          return this.createError({
            message: "Only video files are allowed",
            path: "file",
          });
        }
        return true;
      }
    ),
});

type SCAForm = {
  title: string;
  description: string;
  vimeolink?: string;
  file?: FileList | null;
};

const SCA = (props: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSCAListAPI());
  }, []);

  const {
    register,
    formState: { errors },
    setValue,
    control,
    watch,
    handleSubmit,
  } = useForm<SCAForm>({
    resolver: yupResolver(validationSchema),
  });

  const scaData = useAppSelector((state) => state.sca.SCAdata);
  const formValues = watch();
  const [id, setID] = useState<string | null>(null);
  const [name, setName] = useState<string>("");
  const editSCA = (i: getSCAList) => {
    setValue("description", i.text);
    setValue("title", i.headline);
    setValue("vimeolink", i.vimeo_link);
    setID(i.id);
    setName(i.name);
  };

  useEffect(() => {
    if (formValues.file) {
      setValue("vimeolink", "");
    }
  }, [formValues.file]);
  useEffect(() => {
    if (formValues.vimeolink) {
      setValue("file", null);
    }
  }, [formValues.vimeolink]);

  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1)?.map((error: any) => notifyError(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const onsubmit = (data: SCAForm) => {
    let formData = new FormData();
    if (id) {
      formData.append("fundamental_id", id);
      formData.append("headline", data.title);
      formData.append("text", data.description);
      if (data.vimeolink && data.vimeolink !== "") {
        formData.append("vimeo_link", data.vimeolink);
      } else if (data.file) {
        formData.append("video", data.file[0]);
      }
    }
    dispatch(postSCAListAPI(formData)).then((res) => {
      if (postSCAListAPI.fulfilled.match(res)) {
        notifySuccess(res.payload.message);
      }
    });
  };

  const loading = useAppSelector((state) => state.sca.postSCAdata);
  return (
    <>
      <BoxLayout HeaderName="Self Check audit" sectionName="Self Check Audit">
        <div className="grid grid-cols-10 gap-4 px-4">
          <div className="col-span-3 border rounded-xl">
            <div>
              <div className="px-3 py-3  font-medium border-b">
                All Fundamentals
              </div>

              <div className="flex justify-center flex-col px-3">
                {scaData.map((i, index) => (
                  <div className="flex items-center justify-between">
                    <div className="px-2 my-3">
                      <span className="pr-2 font-normal">{index + 1}.</span>
                      <span className=" px-2 font-normal">{i.name}</span>
                    </div>
                    <span
                      role="button"
                      onClick={() => editSCA(i)}
                      className="border-l-2 px-2"
                    >
                      {id === i.id ? (
                        <img src={activeediticon} alt="" />
                      ) : (
                        <img src={editicon} alt="" />
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className={classnames("col-span-7 border rounded-xl", {
              "opacity-40  cursor-not-allowed": !id,
            })}
          >
            <div className="px-3 py-3  font-medium border-b">
              All Fundamentals{" "}
              {id ? (
                <span className="text-sm text-blue-400">- {name}</span>
              ) : (
                ""
              )}
            </div>
            <form
              onSubmit={handleSubmit(onsubmit)}
              className=" flex flex-col gap-4 p-3"
            >
              <InputComponent
                inputRef="title"
                label="Title"
                register={register}
                value=""
                astric
                disabled={!id}
                className={classnames("", {
                  "cursor-not-allowed": !id,
                })}
              />
              <TextEditor
                inputRef="description"
                name="description"
                label="Description"
                control={control}
                register={register}
                values=""
                className={classnames("", {
                  "cursor-not-allowed": !id,
                })}
              />
              <div className="flex gap-4 items-center">
                <UploadFileComponents
                  control={control}
                  id="file"
                  inputRef="file"
                  label="Add File"
                  value={formValues?.file || ""}
                  disabled={!id}
                  onClick={() => setValue("file", null)}
                />
                <InputComponent
                  inputRef="vimeolink"
                  label="Vimeo Link"
                  register={register}
                  value=""
                  disabled={!id}
                  className={classnames("", {
                    "cursor-not-allowed": !id,
                  })}
                />
              </div>
              <div aria-disabled className="grid grid-cols-10 justify-end">
                <ButtonComponent
                  CTA="Save"
                  varient="blue"
                  buttonType="submit"
                  loading={loading}
                  disabled={!id}
                  className={classnames("col-start-10", {})}
                />
              </div>
            </form>
          </div>
        </div>
      </BoxLayout>
    </>
  );
};

export default SCA;
