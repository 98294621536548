import BoxLayout from "../../../../Containers/Layout/BoxLayout";
import ProfileBox from "./ProfileSections/ProfileBox";
import ProfileInfo from "./ProfileSections/ProfileInfo";
import ProfileStats from "./ProfileSections/ProfileStats";
import ProfileTimeline from "./ProfileSections/ProfileTimeline";
import ProfileSummary from "./ProfileSections/ProfileSummary";
import { useAppDispatch, useAppSelector } from "../../../../Redux/app/hooks";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  clearDashboardData,
  getProfile,
  postFreeUser,
} from "../../../../Redux/features/UserDashboardSlice";
import Switch from "../../../../Components/Inputs/FormSwitchComponent";
import { useForm } from "react-hook-form";
import { notifyError, notifySuccess } from "../../../../helper";
import className from "classnames";

type Props = {};

const ProfilePage = (props: Props) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getProfile(id));
    }
    return () => {};
  }, []);

  const calenderStreak = useAppSelector(
    (state) => state.UserDashboard.dashBoardData.streak_calender
  );
  const ProfileData = useAppSelector(
    (state) => state.UserDashboard.dashBoardData
  );
  const findLabelObject = (
    searchLabel: string,
    array: Array<{ value: string; label: string }>
  ) => {
    return (
      array.find((item) => item.value === String(searchLabel)) || {
        label: "",
        value: "0",
      }
    );
  };
  const { register, watch } = useForm();
  const checked = useAppSelector(
    (state) => state.UserDashboard.dashBoardData.user.is_free
  );

  useEffect(() => {
    console.log("is_free");
  }, [checked]);
  const activePlan = useAppSelector(
    (state) => state.UserDashboard.dashBoardData.user.active_plan_name
  );
  console.log(checked);
  return (
    <>
      <BoxLayout
        HeaderName="User Profile"
        sectionName=""
        headerSection={
          <div className="inline-block px-6">
            <Switch
              label="Free User"
              checked={checked}
              className={className("", {
                "cursor-not-allowed":
                  activePlan !== "7-Day Trial" && activePlan !== "",
                "cursor-pointer":
                  activePlan === "7-Day Trial" || activePlan === "",
              })}
              disabled={activePlan !== "7-Day Trial" && activePlan !== ""}
              // register={register}
              name="is_free"
              onChange={(e) => {
                console.log(id);
                if (id) {
                  console.log("sds");
                  dispatch(
                    postFreeUser({ is_free: e.target.checked, user_id: id })
                  ).then((res: any) => {
                    if (res.payload.success) {
                      notifySuccess("User Payment Status Changed");
                    }
                  });
                }
              }}
            />
          </div>
        }
      >
        <div className="grid grid-cols-9 example gap-5 grid-row-8   h-[calc(100vh-6.5rem-5.475rem)]  overflow-auto w-full bg-secondary-dark">
          <div className="col-span-2 example shadow-[10px_0px_0px_0px_#FF9F00_inset,_0px_4px_10px_0px_rgba(137,137,137,0.20)]  bg-white rounded-xl">
            <div className="shadow-section h-full rounded-xl">
              <ProfileBox
                imgSrc={ProfileData.user.profile_picture}
                fullName={ProfileData?.user?.full_name}
                groupID={ProfileData.user.group_id}
                registrationDate={ProfileData?.user?.registered_at}
              />

              <ProfileInfo
                status={ProfileData?.user.status}
                activePlanName={ProfileData?.user.active_plan_name}
                timezoneField={ProfileData?.user.timezone_field}
                countryFlag={ProfileData?.user.country_flag}
                BirthDay={ProfileData?.user.birthday}
                fullName={ProfileData?.user?.full_name}
                Height={ProfileData?.user?.height}
                Weight={ProfileData?.user?.weight}
                address={ProfileData?.user?.address}
                countryZone={ProfileData?.user?.time_zone}
                email={ProfileData?.user?.email}
                number={""}
                userid={ProfileData?.user?.userid}
                registrationDate={ProfileData?.user?.registered_at}
              />
            </div>
          </div>
          <div className="col-span-1 flex flex-col gap-4 h-[calc(100vh-6.5rem-5.475rem)]">
            <ProfileStats
              showBlue={ProfileData?.show_blue}
              streak={ProfileData?.training_streak}
              maxStreak={ProfileData?.max_training_streak}
              moveDrills={ProfileData?.reps?.move_drills}
              recallCards={ProfileData?.reps?.recall_cards}
              swingDrills={ProfileData?.reps?.swing_drills}
              trustShots={ProfileData?.reps?.trust_shots}
            />
          </div>
          <div className="col-span-3 gap-4 flex flex-col h-[calc(100vh-6.5rem-5.475rem)] justif items-center">
            <ProfileTimeline
              DailyRemainder={ProfileData.user.set_daily_reminder_time}
              calenderStreak={calenderStreak}
              detailsData={ProfileData.question_answer}
              summaryData={ProfileData.question_answer.slice(0, 1)}
              trainningPlan={findLabelObject(
                String(ProfileData?.training_intention?.length),
                [
                  { value: "1", label: "Weak" },
                  { value: "2", label: "OK" },
                  { value: "3", label: "Solid" },
                  { value: "4", label: "Good" },
                  { value: "5", label: "Great" },
                  { value: "6", label: "Player" },
                  { value: "7", label: "Pro" },
                ]
              )}
            />
          </div>
          <div className="col-span-3 gap-4 flex flex-col h-[calc(100vh-6.5rem-5.475rem)] justify-between">
            <ProfileSummary
              applyData={ProfileData?.activity?.apply}
              trainData={ProfileData?.activity?.train}
              trustData={ProfileData?.activity.trust}
              feedBackData={ProfileData?.feedback}
              sccRatings={ProfileData.rating.scc_rating}
              sessionRatings={ProfileData.rating.session_rating}
              weeklyRatings={ProfileData.rating.weekly_rating}
              contacts={ProfileData.friend_support}
            />
          </div>
        </div>
      </BoxLayout>
    </>
  );
};

export default ProfilePage;
