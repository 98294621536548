import React, { useEffect } from "react";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import HyperLink from "../../Components/HyperLink/HyperLink";
import { useLocation, useParams } from "react-router-dom";
import {
  clearDashboardData,
  getProfile,
} from "../../Redux/features/UserDashboardSlice";
import { categorizeDate } from "../../utils/helper";

type Props = {
  id: string;
};

const SideBarSection = ({ id }: Props) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const userdashboard = useAppSelector(
    (state) => state.UserDashboard.dashBoardData
  );
  const params = useParams();
  useEffect(() => {
    if (id) {
      dispatch(getProfile(id));
    }
    return () => {};
  }, []);
  useEffect(() => {
    return () => {
      dispatch(clearDashboardData());
    };
  }, []);
  console.log(userdashboard?.user?.last_login_at, "Sss");
  return (
    <div>
      <>
        <div className="flex flex-col gap-3">
          <p className="mx-2 font-medium">User Management</p>
          <li
            className={classNames("    flex gap-4", {
              "bg-[#EAF0F3] p-3 rounded-xl": true,
            })}
          >
            <img
              className="w-12 h-12 rounded-full"
              src={userdashboard?.user.profile_picture || "/images/avatar.jpg"}
              alt=""
            />
            <span
              className={classNames("text-base ", {
                "text-[#1F3161] font-medium": true,
                // "text-[#767576] font-normal": item.showid !== showid,
              })}
            >
              <span className="w-[100px] block overflow-hidden  text-ellipsis whitespace-nowrap">
                {userdashboard.user.full_name}
              </span>
              <span className="w-[100px] block overflow-hidden text-xs text-start py-2 text-[#8B8D97] font-medium text-ellipsis whitespace-nowrap">
                <span className="flex gap-1 items-center">
                  <span className="w-2 h-2  rounded-full inline-block  bg-[#FFCC91]"></span>
                  {userdashboard.user.last_login_at !== "" ? (
                    <p className="mx-1">
                      {categorizeDate(userdashboard?.user?.last_login_at)}{" "}
                    </p>
                  ) : (
                    ""
                  )}
                </span>
              </span>
            </span>
          </li>
          <ul className="border-l-[#C6D6DD] flex flex-col gap-3 mb-3 text-sm ml-10 pl-4 border-l">
            {[
              {
                id: "profile",
                label: "Profile",
                path: `/usermanagement/userlist/${id}/profile`,
              },
              {
                id: "rating",
                label: "Rating",
                path: `/usermanagement/userlist/${id}/rating`,
              },
              {
                id: "activity",
                label: "Activity",
                path: `/usermanagement/userlist/${id}/activity`,
              },
              {
                id: "feedback",
                label: "Feedback",
                path: `/usermanagement/userlist/${id}/feedback`,
              },
              {
                id: "subscription",
                label: "Subscription",
                path: `/usermanagement/userlist/${id}/subscription`,
              },
              {
                id: "notification",
                label: "Notification",
                path: `/usermanagement/userlist/${id}/notification`,
              },
            ].map((i) => (
              <HyperLink type="menu" path={i.path}>
                <li
                  className={classNames("flex  items-center gap-4", {
                    "text-[#767576]": location.pathname !== i.path,
                    "text-[#1F3161]": location.pathname === i.path,
                  })}
                >
                  <span
                    className={classNames(
                      "h-1 w-1 rounded-full  inline-block",
                      {
                        "bg-[#767576]": location.pathname !== i.path,
                        "bg-bluegradient": location.pathname === i.path,
                      }
                    )}
                  ></span>{" "}
                  {i.label}
                </li>
              </HyperLink>
            ))}
          </ul>
        </div>
        <hr />
      </>
    </div>
  );
};

export default SideBarSection;
