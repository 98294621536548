import ActivityPage from "../../Pages/Users/UserDetails/Activity/ActivityPage";
import FeedbackPage from "../../Pages/Users/UserDetails/Feedback/FeedbackPage";
import Notification from "../../Pages/Users/UserDetails/Notification/Notification";
import ProfilePage from "../../Pages/Users/UserDetails/Profile/ProfilePage";
import RatingPage from "../../Pages/Users/UserDetails/Rating/RatingPage";
import Subscription from "../../Pages/Users/UserDetails/Subscription/Subscription";
import Support from "../../Pages/Users/UserDetails/Support/Support";
import AdminRoutes from "../../auth/AdminRoutes";

export const userDashboardRoutes = [
  {
    id: "userdash1",
    name: "Rating List",
    module: "User Management",
    path: "/usermanagement/userlist/:id/rating",
    component: RatingPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "User Dashboard",
        path: "/usermanagement/userlist",
      },
      {
        name: "Rating List",
        path: "#",
      },
    ],
  },
  {
    id: "userdash2",
    name: "Profile",
    module: "User Management",
    path: "/usermanagement/userlist/:id/profile",
    component: ProfilePage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "User Dashboard",
        path: "/usermanagement/userlist",
      },
      {
        name: "Profile",
        path: "#",
      },
    ],
  },
  {
    id: "userdash3",
    name: "Activities",
    module: "User Management",
    path: "/usermanagement/userlist/:id/activity",
    component: ActivityPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "User Dashboard",
        path: "/usermanagement/userlist",
      },
      {
        name: "Activity",
        path: "#",
      },
    ],
  },
  {
    id: "userdash4",
    name: "Support",
    module: "User Management",
    path: "/usermanagement/userlist/:id/support",
    component: Support,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "User Dashboard",
        path: "/usermanagement/userlist",
      },
      {
        name: "Support",
        path: "#",
      },
    ],
  },
  {
    id: "userdash5",
    name: "Feedback",
    module: "User Management",
    path: "/usermanagement/userlist/:id/feedback",
    component: FeedbackPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "User Dashboard",
        path: "/usermanagement/userlist",
      },
      {
        name: "Feedback",
        path: "#",
      },
    ],
  },
  {
    id: "userdash6",
    name: "Subscription",
    module: "User Management",
    path: "/usermanagement/userlist/:id/subscription",
    component: Subscription,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "User Dashboard",
        path: "/usermanagement/userlist",
      },
      {
        name: "subscription",
        path: "#",
      },
    ],
  },
  {
    id: "userdash7",
    name: "Notification",
    module: "User Management",
    path: "/usermanagement/userlist/:id/notification",
    component: Notification,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "User Dashboard",
        path: "/usermanagement/userlist",
      },
      {
        name: "Notification",
        path: "#",
      },
    ],
  },
];
